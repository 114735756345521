import { useEffect, useRef, useState } from 'react';
import {
  Configure,
  Index,
  useHits,
  useInstantSearch,
} from 'react-instantsearch';
import classNames from 'classnames';
import algoliasearch from 'algoliasearch';
import countryList from 'country-list';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import VoiceSearch from './VoiceSearch/VoiceSearch';
import IconClose from '../IconClose/IconClose';
import IconCard from '../SavedCardDetails/IconCard/IconCard';
import { H4, H5 } from '../Heading/Heading';
import { createSlug } from '../../util/urlHelpers';
import { convertPrice, formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import { types } from '../../util/sdkLoader';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';

import { displayPrice } from '../../util/configHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { FormattedMessage } from '../../util/reactIntl';
import { getCenterFromBounds } from '../../containers/SearchPage/AlgoliaSearchPage/MainSearchContent/MainSearchContent';
import { IoIosStarHalf } from 'react-icons/io';
import { IoStar } from 'react-icons/io5';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import css from './AlgoliaKeywordSearch.module.css';

const { UUID } = types;

const MODAL_BREAKPOINT = 768;
const isWindowDefined = typeof window !== 'undefined';
const isDesktopLayout = isWindowDefined && window.innerWidth > MODAL_BREAKPOINT;

const recentSearchesKey = 'recent-searches';

const PRODUCT_CLICKED_EVENT = 'Product Clicked';
const STORE_CLICKED_EVENT = 'Store Clicked';

const MAX_STORES = 16;
const MAX_RENTALS = 150;

const ALGOLIA_CLIENT_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY;
const ALGOLIA_LISTINGS_INDEX = process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX;
const ALGOLIA_STORES_INDEX = process.env.REACT_APP_ALGOLIA_USERS_INDEX;

const searchClient = algoliasearch(ALGOLIA_CLIENT_ID, ALGOLIA_API_KEY);

const rentalsIndex = searchClient.initIndex(ALGOLIA_LISTINGS_INDEX);
const storesIndex = searchClient.initIndex(ALGOLIA_STORES_INDEX);

function removeDuplicates(hits) {
  const uniqueHits = new Map();

  hits.forEach(h => {
    // Use the product id as the key
    if (!uniqueHits.has(h.objectID)) {
      uniqueHits.set(h.objectID, h);
    }
  });

  // Convert the map values back to an array
  return Array.from(uniqueHits.values());
}

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const PriceMaybe = props => {
  const {
    price,
    listingType,
    defaultPriceKey,
    transactionProcessAlias,
    config,
    intl,
    selectedCurrency,
  } = props;

  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(
    conf => conf.listingType === listingType
  );
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(
    price,
    selectedCurrency,
    intl
  );

  return (
    <div className={css.price}>
      <span title={priceTitle}>{formattedPrice} </span>
      {isBookable ? (
        <span className={css.perUnit}>
          <FormattedMessage
            id="ListingCard.perUnit"
            values={{ defaultPriceKey: defaultPriceKey || 'perDayPrice' }}
          />
        </span>
      ) : null}
    </div>
  );
};

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text; // Return the original text if it's within or equal to the maxLength
  } else {
    return text.slice(0, maxLength) + '...'; // Truncate the text and add ellipsis
  }
}

// Search Inputs
const RecentSearchItem = props => {
  const { label, id, refine, setKeyword, setRecentSearces } = props;
  const handleClick = id => {
    refine(id);
    setKeyword(id);
  };

  const handleDelete = () => {
    const recentSearchItems =
      typeof window != 'undefined' && window.localStorage
        ? window.localStorage.getItem(recentSearchesKey)
        : null;
    const parsedItems = recentSearchItems ? JSON.parse(recentSearchItems) : [];
    if (parsedItems.length) {
      const filteredItems = parsedItems.filter(i => i.id !== id);
      window.localStorage.setItem(
        recentSearchesKey,
        JSON.stringify(filteredItems)
      );
      setRecentSearces(filteredItems);
    }
  };

  return (
    <li className={css.recentSearchItem}>
      <span className={css.recentUse} onClick={() => handleClick(id)}>
        <IconCard brand="recent" />
      </span>
      <span className={css.recentLabel}>{label}</span>
      <span className={css.recentDelete} onClick={handleDelete}>
        <IconCard brand="delete" />
      </span>
    </li>
  );
};

// Rental Hit
const RentalHit = props => {
  const {
    rental = {},
    intl,
    onClick,
    history,
    routes,
    exchangeRates,
    currentUserCurrency,
    config,
    queryID,
    onActivateListing,
  } = props;

  const id = rental.objectID;
  const {
    title = '',
    price,
    user: { displayName },
    publicData,
    additional,
  } = rental;

  const {
    category,
    city,
    listingImages = [],
    prices,
    listingType,
    transactionProcessAlias,
    defaultPriceKey,
  } = publicData || {};

  const aspectWidth = 2;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';

  const slug = createSlug(title);
  const convertedPrice = !!price
    ? convertPrice(
      exchangeRates,
      price.amount,
      price.currency,
      currentUserCurrency
    )
    : null;

  const queryString =
    !!category && !!city && !!queryID
      ? `?city=${city.toLowerCase()}&category=${category.toLowerCase()}&queryID=${queryID}`
      : !!category && !!queryID
        ? `?category=${category.toLowerCase()}&queryID=${queryID}`
        : !!city && !!queryID
          ? `?city=${city.toLowerCase()}&queryID=${queryID}`
          : !!queryID
            ? `?queryID=${queryID}`
            : null;

  const firstImage = listingImages[0];
  const firstImageMaybe =
    !!firstImage && firstImage.secure_url ? (
      <img
        className={css.imageWrapper}
        src={firstImage.secure_url}
        alt={slug}
      />
    ) : (
      <div className={css.noImageWrapper}>
        <svg
          color="#333"
          width="100px"
          height="100px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
      </div>
    );

  const handleClick = () => {
    onClick();
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routes,
        { id, slug },
        { search: queryString }
      )
    );
  };

  const setActivePropsMaybe = onActivateListing
    ? {
      onMouseEnter: () => onActivateListing(new UUID(id)),
      onMouseLeave: () => onActivateListing(null),
    }
    : {};

  return (
    <div className={css.searchRentalHit} onClick={handleClick}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {firstImageMaybe}
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>{truncateText(title, 30)}</div>
        </div>

        <PriceMaybe
          price={convertedPrice}
          listingType={listingType}
          transactionProcessAlias={transactionProcessAlias}
          defaultPriceKey={defaultPriceKey}
          config={config}
          intl={intl}
          selectedCurrency={currentUserCurrency}
        />
        <div className={css.morePrices}>See more Options</div>
      </div>
      {/* {additional ? <span className={css.additional}>Additional</span> : null} */}
    </div>
  );
};

// Rental Hits
const SearchRentalHits = props => {
  const [hitsTimer, setHitsTimer] = useState(null);
  const [renderableRentals, setRenderableRentals] = useState([]);
  const { results, items, sendEvent, nbHits } = useHits(props);
  const { status, error, indexUiState } = useInstantSearch({
    catchError: true,
  });

  const queryID = results.queryID;
  const { currentUser, aroundLatLng, intl } = props;

  const sectionTitle = intl.formatMessage({ id: "AlgoliaKeywordSearch.rentalsTitle" });
  const resultsLength = items.length;
  const rentalsToFetch = MAX_RENTALS - resultsLength;
  const indexUiStateInitialized = Object.values(indexUiState).length;
  const coverEarthRadius = 20037500; // Radius to cover the entire earth's circumference from a given point in meters.
  const aroundLatLngMaybe = aroundLatLng
    ? { aroundLatLng, aroundRadius: coverEarthRadius }
    : {};

  const readyToFetch = indexUiStateInitialized && status === 'idle';
  // console.log(aroundLatLngMaybe, 'maybe');

  // To meet minimum records need, we have to fetch more.
  useEffect(() => {
    let timer;

    if (indexUiStateInitialized) {
      // See if there are the required number of hits or not
      // in index.
      if (hitsTimer) {
        clearTimeout(hitsTimer);
      }

      timer = setTimeout(() => {
        rentalsIndex
          .search('', {
            hitsPerPage: 0,
            ...aroundLatLngMaybe,
          })
          .then(({ nbHits: totalRecords }) => {
            if (
              totalRecords >= MAX_RENTALS &&
              rentalsToFetch > 0 &&
              readyToFetch &&
              (typeof nbHits == 'undefined' || nbHits < MAX_RENTALS)
            ) {
              const { query = '', menu = {} } = indexUiState || {};
              const searchedCategory = menu['publicData.category'];
              const filters = !!searchedCategory
                ? `category:${searchedCategory}`
                : '';
              // Fetching additional results equal to the current results
              // length will ensure the minimum records requirement.
              // If the following query fetches the some same results
              // the duplicates are removed, but the additional fetched
              // results would fulfil the need of 150.
              rentalsIndex
                .search(query, {
                  filters,
                  hitsPerPage: rentalsToFetch + resultsLength,
                  ...aroundLatLngMaybe,
                })
                .then(res => {
                  const { hits } = res;

                  // Remove duplicates
                  const uniqueHits = removeDuplicates([
                    ...items,
                    ...hits.map(h => ({ ...h, additional: true })),
                  ]);

                  return uniqueHits;
                })
                .then(uniqueHits => {
                  // Check if still need to fetch more.
                  const toFetch = MAX_RENTALS - uniqueHits.length;
                  if (toFetch > 0) {
                    rentalsIndex
                      .search('', {
                        hitsPerPage: toFetch + uniqueHits.length,
                        ...aroundLatLngMaybe,
                      })
                      .then(({ hits }) => {
                        const completeUniqueHits = removeDuplicates([
                          ...uniqueHits,
                          ...hits.map(h => ({ ...h, additional: true })),
                        ]);
                        setRenderableRentals(completeUniqueHits.slice(0, 150));
                      });
                  } else {
                    setRenderableRentals(uniqueHits.slice(0, 150));
                  }
                });
            } else if (readyToFetch) {
              setRenderableRentals(items);
            }
          });
      }, 250);

      setHitsTimer(timer);
    }
    return () => clearTimeout(timer);
  }, [rentalsToFetch, readyToFetch]);

  const userTokenMaybe =
    currentUser && currentUser.id ? { userToken: currentUser.id.uuid } : {};

  return (
    <div className={css.searchHits} id="hits">
      <H5 className={css.searchHitsTitle}>{sectionTitle}</H5>
      <div className={css.hitsContianer}>
        {(renderableRentals && renderableRentals.length
          ? renderableRentals
          : []
        ).map(i => (
          <RentalHit
            key={i.objectID}
            rental={i}
            onClick={() => {
              sendEvent('click', i, PRODUCT_CLICKED_EVENT, {
                objectIds: [i.objectID],
                ...userTokenMaybe,
              });
            }}
            queryID={queryID}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

const Ratings = props => {
  const { rating: ratingPeruser = 5, reviews: totalRatings } = props;
  const starArray = [1, 2, 3, 4, 5];

  const filledStars = Math.floor(ratingPeruser);
  const hasHalfStar = ratingPeruser % 1 !== 0;

  return (
    <div className={css.ratingContent}>
      {starArray.map(star => {
        return star === filledStars + 1 && hasHalfStar ? (
          <IoIosStarHalf className={css.starIconGold} key={star} />
        ) : (
          <IoStar
            className={
              star <= filledStars ? css.starIconGold : css.starIconGrey
            }
            key={star}
          />
        );
      })}
    </div>
  );
};

// Store Hit
const StoreHit = props => {
  const { store = {}, onClick, history, routes } = props;

  const id = store.objectID;
  const { displayName: userName, profileImage, publicData, additional } = store;

  const { city, country: countryCode, rating, reviews, storeName } = publicData || {};
  const countryName = !!countryCode?.trim()
    ? countryList.getName(countryCode)
    : null;

  const displayName = !!storeName ? storeName : userName;

  const searchString =
    !!city && !!displayName
      ? `?name=${displayName}&city=${city}`
      : !!displayName
        ? `?name=${displayName}`
        : !!city
          ? `?city=${city}`
          : '';

  const aspectWidth = 1;
  const aspectHeight = 1;

  const cityAndCountryMaybe =
    city?.trim() && countryName?.trim() ? (
      <p className={css.location}>
        {city}, {countryName}
      </p>
    ) : city ? (
      <p className={css.location}>{city}</p>
    ) : countryName ? (
      <p className={css.location}>{countryName}</p>
    ) : null;

  const profileImageMaybe =
    !!profileImage && profileImage.secure_url ? (
      <img
        className={css.avtar}
        src={profileImage.secure_url}
        alt={displayName}
      />
    ) : (
      <div className={css.noImageContainer}>
        <H4 className={css.noImage}>{displayName}</H4>
      </div>
    );

  const handleStoreClick = () => {
    onClick();
    history.push(
      createResourceLocatorString(
        'ProfilePage',
        routes,
        { id },
        { search: searchString }
      )
    );
  };

  return (
    <div onClick={handleStoreClick} className={css.searchStoriesHit}>
      {profileImageMaybe}
      <div className={css.storeInfo}>
        <h6 className={css.storeName}>{truncateText(displayName, 30)}</h6>
        {cityAndCountryMaybe}
        <div className={css.rating}>
          <Ratings rating={rating} reviews={reviews} />
        </div>
      </div>
      {/* {additional ? <span className={css.additional}>Additional</span> : null} */}
    </div>
  );
};

// Store Hits
const SearchStoreHits = props => {
  const [hitsTimer, setHitsTimer] = useState(null);
  const { routes, history, intl } = props;
  const [renderableStores, setRenderableStores] = useState([]);

  const { items, sendEvent, nbHits } = useHits(props);
  const { status, error, indexUiState } = useInstantSearch({
    catchError: true,
  });

  const sortedItems = items.sort((a, b) => b.timestamp - a.timestamp);

  const resultsLength = items.length;
  const storesToFetch = MAX_STORES - resultsLength;
  const indexUiStateInitialized = Object.values(indexUiState).length;
  const sectionTitle = intl.formatMessage({ id: "AlgoliaKeywordSearch.storesTitle" });


  // Want to show records from every where sorted by location.
  const coverEarthRadius = 20037500; // Radius to cover the entire earth's circumference from a given point in meters.

  // Need to fetch more if minimum hits requirement
  // is not fulfilled.
  useEffect(() => {
    let timer;
    if (indexUiStateInitialized) {
      if (hitsTimer) {
        clearTimeout(hitsTimer);
      }
      timer = setTimeout(() => {
        const { configure = {} } = indexUiState || {};
        const { filters, aroundLatLng, query } = configure;
        const aroundLatLngMaybe = aroundLatLng
          ? { aroundLatLng, aroundRadius: coverEarthRadius }
          : {};
        // will only fetch if available. Otherwise will stuck in an
        // infinite loop.
        storesIndex
          .search('', { ...aroundLatLngMaybe, filters })
          .then(({ nbHits: totalRecords }) => {
            if (
              totalRecords >= MAX_STORES &&
              storesToFetch > 0 &&
              status === 'idle' &&
              (typeof nbHits == 'undefined' || nbHits < MAX_STORES)
            ) {
              // Fetching additional results equal to the current results
              // length will ensure the minimum records requirement.
              // If the following query fetches the some same results
              // the duplicates are removed, but the additional fetched
              // results would fulfil the need of 150.

              // Widely expanded radius with provided lat lng will ensure
              // records are sorted.
              storesIndex
                .search(query, {
                  hitsPerPage: storesToFetch + resultsLength,
                  filters,
                  ...aroundLatLngMaybe,
                })
                .then(res => {
                  const { hits } = res;

                  const uniqueHits = removeDuplicates([
                    ...sortedItems,
                    ...hits.sort((a, b) => b.timestamp - a.timestamp).map(h => ({ ...h, additional: true })),
                  ]);

                  return uniqueHits;
                })
                .then(uniqueHits => {
                  const toFetch = MAX_STORES - uniqueHits.length;
                  // Remove query and then try again.
                  if (toFetch > 0) {
                    storesIndex
                      .search('', {
                        hitsPerPage: toFetch + uniqueHits.length,
                        filters,
                        ...aroundLatLngMaybe,
                      })
                      .then(({ hits }) => {
                        const completeUniqueHits = removeDuplicates([
                          ...uniqueHits,
                          ...hits.sort((a, b) => b.timestamp - a.timestamp).map(h => ({ ...h, additional: true })),
                        ]);
                        setRenderableStores(completeUniqueHits.slice(0, 16));
                      });
                  } else {
                    setRenderableStores(uniqueHits.slice(0, 16));
                  }
                });
            } else if (status === 'idle') {
              setRenderableStores(items);
            }
          });
      }, 250);
      setHitsTimer(timer);
    }

    () => clearTimeout(timer);
  }, [storesToFetch, status]);

  const { currentUser } = props;

  const userTokenMaybe =
    currentUser && currentUser.id ? { userToken: currentUser.id.uuid } : {};

  return (
    <div className={css.searchHits} id="hits">
      <H5 className={css.searchHitsTitle}>{sectionTitle}</H5>
      <div className={css.hitsContianer}>
        {renderableStores.map(i => (
          <StoreHit
            key={i.objectID}
            store={i}
            routes={routes}
            history={history}
            onClick={() =>
              sendEvent('click', i, STORE_CLICKED_EVENT, {
                objectIds: [i.objectID],
                ...userTokenMaybe,
              })
            }
          />
        ))}
      </div>
    </div>
  );
};

const KeywordSearch = props => {
  const {
    onSearchChange,
    searchInputText,
    routes,
    history,
    currentUserCurrency,
    currentUser,
    exchangeRates,
    config,
    intl,
    name,
    initialValue,
    refineKeyword,
    clearKeywordsClass,
    autoFocus = false,
    onFocus,
    selectedBounds,
    onActivateListing,
  } = props;

  const center = getCenterFromBounds(selectedBounds);
  const { keyword: initialKeyword } = initialValue || {};
  const searchBoxRef = useRef(null);
  const [keyword, setKeyword] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [strokTimer, setStrokTimer] = useState(null);
  const [isRecentSearches, setIsRecentSearches] = useState(false);

  const recentSearchesFromLocalStorage =
    typeof window != 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem(recentSearchesKey) &&
      window.localStorage.getItem(recentSearchesKey).length
      ? JSON.parse(window.localStorage.getItem(recentSearchesKey))
      : [];
  const [recentSearches, setRecentSearces] = useState(
    recentSearchesFromLocalStorage
  );

  useEffect(() => {
    // Landing page don't pass refineKeyword function
    if (refineKeyword && typeof refineKeyword == 'function') {
      setKeyword(initialKeyword);
      refineKeyword(initialKeyword);
    }
  }, [initialKeyword]);

  const handleChange = text => {
    if (strokTimer) {
      clearTimeout(strokTimer);
    }

    const timer = setTimeout(() => {
      // console.log('calling...', text)
      onSearchChange(text);
      if (searchBoxRef && searchBoxRef.current) {
        searchBoxRef.current.scrollTo(0, 0);
      }
    }, 150);

    setKeyword(text);
    setStrokTimer(timer);
  };

  const handleBlurSearchInput = () => {
    setIsSearchFocused(false);
    if (!!keyword?.trim()) {
      const isOldKeyword = recentSearches?.findIndex(s => s.id === keyword);

      if (isOldKeyword === -1) {
        const limitedRecentSearches = recentSearches.splice(0, 9);
        const latestSearches = [
          { label: keyword, id: keyword },
          ...limitedRecentSearches,
        ];

        setRecentSearces(latestSearches);
        if (typeof window != 'undefined' && window.localStorage) {
          window.localStorage.setItem(
            recentSearchesKey,
            JSON.stringify(latestSearches)
          );
        }
      }
    }
  };

  const aroundLatLngMaybe =
    center && center.lat && center.lng
      ? { aroundLatLng: `${center.lat}, ${center.lng}`, aroundRadius: 50000 }
      : {};

  return (
    <div className={css.keywordSearchContainer}>
      <OutsideClickHandler
        className={css.outSideClickContainer}
        onOutsideClick={handleBlurSearchInput}
      >
        <div className={css.keywordSearchWrapper}>
          <input
            className={css.keywordSearch}
            value={keyword}
            autoFocus={autoFocus}
            onChange={e => handleChange(e.target.value)}
            type="text"
            placeholder={searchInputText || 'Search by keyword'}
            onFocus={() => {
              setIsSearchFocused(true);
              if (typeof onFocus == 'function') {
                onFocus();
              }
            }}
            name={name}
          />
          {!!keyword ? (
            <button
              className={clearKeywordsClass}
              type="button"
              onClick={() => {
                setKeyword('');
                onSearchChange('');
                if (searchBoxRef && searchBoxRef.current) {
                  searchBoxRef.current.scrollTo(0, 0);
                }
              }}
            >
              <IconClose size="small" />
            </button>
          ) : null}
          <VoiceSearch onVoiceSearch={transcript => handleChange(transcript)} />
        </div>
        <div
          className={classNames(
            css.kewordSearchContainer,
            !isSearchFocused ? css.closed : ''
          )}
          ref={searchBoxRef}
        >
          <button
            className={css.collapseKeywordSearch}
            type="button"
            onClick={handleBlurSearchInput}
          >
            <IconClose size="big" />
          </button>
          <div className={css.federatedSearchDiv}>
            {/* Recent Searches */}
            <div className={css.recentSearches}>
              <div className={css.recentSearchesHeader}>
                <h5 className={css.searchHitsTitle}><FormattedMessage id='AlgoliaKeywordSearch.recentSearch' /></h5>
                {/* Hide the toggle option on desktop */}
                {!isDesktopLayout ?<button
                  className={css.arrowButton}
                  type="button"
                  onClick={() => setIsRecentSearches(!isRecentSearches)}
                >
                  <IconArrowHead
                    direction="down"
                    size="small"
                    rootClassName={isRecentSearches ? classNames(css.arrowIcon, css.active) : css.arrowIcon}
                  />
                </button>:null}
              </div>
              {(isRecentSearches || isDesktopLayout) ? <ul className={css.totalItem}>
                {recentSearches.slice(0, 10).map(r => (
                  <RecentSearchItem
                    key={r.id}
                    label={r.label}
                    id={r.id}
                    refine={onSearchChange}
                    setKeyword={setKeyword}
                    setRecentSearces={setRecentSearces}
                  />
                ))}
              </ul> : null}
            </div>
            <div className={css.imageSeacrhResult}>
              {/*  Search Rentals */}
              <Index indexName={process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX}>
                <SearchRentalHits
                  history={history}
                  currentUserCurrency={currentUserCurrency}
                  currentUser={currentUser}
                  exchangeRates={exchangeRates}
                  routes={routes}
                  config={config}
                  {...aroundLatLngMaybe}
                  intl={intl}
                  onActivateListing={onActivateListing}
                />
              </Index>

              {/* Search Stores */}
              <Index indexName={process.env.REACT_APP_ALGOLIA_USERS_INDEX}>
                <Configure
                  filters={`publicData.totalListings > 9`}
                  hitsPerPage={16}
                  query={keyword || ''}
                  removeWordsIfNoResults="allOptional"
                  {...aroundLatLngMaybe}
                />
                <SearchStoreHits
                  currentUser={currentUser}
                  history={history}
                  routes={routes}
                  config={config}
                  intl={intl}
                />
              </Index>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default KeywordSearch;
