// import React from "react";
// import classNames from "classnames";
// import { usePagination } from "react-instantsearch";
// import IconCard from "../../../../../components/SavedCardDetails/IconCard/IconCard";

// import css from './Pagination.module.css';

// function PaginationItem({ isDisabled, href, onClick, isActive, ...props }) {
//     if (isDisabled) {
//         return (
//             <li className={css.paginationItem}>
//                 <span {...props} />
//             </li>
//         );
//     };

//     const classes = isActive ? classNames(css.paginationItem, css.active) : css.paginationItem;
//     return (
//         <li>
//             <a
//                 className={classes}
//                 href={href}
//                 onClick={(event) => {
//                     if (isModifierClick(event)) {
//                         return;
//                     }

//                     event.preventDefault();

//                     onClick(event);
//                 }}
//                 {...props}
//             />
//         </li>
//     );
// };

// function isModifierClick(event) {
//     const isMiddleClick = event.button === 1;

//     return Boolean(
//         isMiddleClick ||
//         event.altKey ||
//         event.ctrlKey ||
//         event.metaKey ||
//         event.shiftKey
//     );
// }

// export default function CustomPagination(props) {
//     const {
//         pages,
//         currentRefinement,
//         nbPages,
//         isFirstPage,
//         isLastPage,
//         refine,
//         createURL,
//     } = usePagination(props);
//     const firstPageIndex = 0;
//     const previousPageIndex = currentRefinement - 1;
//     const nextPageIndex = currentRefinement + 1;
//     const lastPageIndex = nbPages - 1;

//     if (pages.length === 1) return null;

//     return (
//         <ul className={css.pagination}>
//             <PaginationItem
//                 className={css.arrow}
//                 isDisabled={isFirstPage}
//                 href={createURL(previousPageIndex)}
//                 onClick={() => refine(previousPageIndex)}
//             >
//                 <IconCard brand="previous" />
//             </PaginationItem>
//             <div className={css.pageNumber}>
//                 {pages.map((page) => {
//                     const label = page + 1;
//                     return (
//                         <PaginationItem
//                             key={page}
//                             isDisabled={false}
//                             aria-label={`Page ${label}`}
//                             href={createURL(page)}
//                             onClick={() => refine(page)}
//                             isActive={currentRefinement === page}
//                         >
//                             {label}
//                         </PaginationItem>
//                     );
//                 })}
//             </div>

//             <PaginationItem
//                 className={css.arrow}
//                 isDisabled={isLastPage}
//                 href={createURL(nextPageIndex)}
//                 onClick={() => refine(nextPageIndex)}
//             >
//                 <IconCard brand="next" />
//             </PaginationItem>
//         </ul>
//     );
// };

import React from 'react';
import css from './Pagination.module.css'; // Import CSS module
import IconCard from '../../../../../components/SavedCardDetails/IconCard/IconCard';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const shouldRender = pageNumbers.length;

  if (!shouldRender) {
    return null;
  }

  return (
    <div className={css.pagination}>
      <button
        className={css.arrow}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
         <IconCard brand="previous" />
      </button>
      <div className={css.pageNumber}>
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            className={`${css.paginationItem} ${
              currentPage === number ? css.active : ''
            }`}
          >
            {number}
          </button>
        ))}
      </div>
      <button
        className={css.arrow}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <IconCard brand="next" />
      </button>
    </div>
  );
};

export default Pagination;
