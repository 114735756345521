import moment from "moment";
import { userActiveListings, currentUserListingsClosed, currentUserTransactionsTotals } from "./api";
import { ensureCurrentUser } from "./data";
import { createInstance } from "sharetribe-flex-sdk";

const clientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const BASE_URL = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL;
const baseUrl = BASE_URL ? { baseUrl: BASE_URL } : {};

const sdk = createInstance({
    clientId,
    ...baseUrl
});

const baseCurrency = "CAD";
const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;




export const initiateIntercomForLoggnedUser = async (currentUser) => {
    try {
        // Initiate Intercom for the authenticated user
        // with the required data:
        // Data required:-
        // User Created Date
        // User ID
        // User First Name
        // User Last Name
        // User Email Address
        // User Phone Number
        // User City
        // User Province or State
        // User Country
        // Number of LIstings
        // Number of times booked
        // Number of times rented
        // Last Login Date
        // Days Inactive
        // User Phone Number
        // User Postal / Zip Code
        // Number of Rentals
        // Time to first listing
        // Total Number of Items Active
        // Total Number of Items Inactive
        // Time to first booking

        // Last Listing Post Date
        // First Listing Post Date

        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        const { id, attributes: { profile, email, createdAt } } = ensuredCurrentUser;
        const { displayName, firstName, lastName, publicData, protectedData } = profile || {};
        const userId = id.uuid;
        const { phoneNumber: phone } = protectedData || {};
        const { country, firstBookingDate } = publicData || {};

        const [
            sdkListingsRes,
            sdkListingsResB,
            responseSales,
            responseOrders,

        ] = await Promise.all([
            sdk.listings.query({ authorId: userId, sort: "-createdAt" }),
            sdk.listings.query({ authorId: userId, sort: "createdAt" }),
            sdk.transactions.query({ only: "sale" }),
            sdk.transactions.query({ only: "order" }),
        ]);
        const activeListingsRes = await userActiveListings(userId)
        const closeListingRes = await currentUserListingsClosed(userId)
        const userTransactionTotals = await currentUserTransactionsTotals()

        // number of listings & First listing creation date
        const { meta: { totalItems: totalListings = 0 }, data } = sdkListingsRes?.data || { meta: {}, data: [] };
        const firstListing = data[0];
        const firstListingCreated = !!firstListing?.attributes?.createdAt ? moment(firstListing.attributes.createdAt).toISOString() : null;

        // Last Listing Date
        const { data: sdkResBData } = sdkListingsResB?.data || { data: [] };
        const lastListing = sdkResBData[0];
        const lastListingCreated = !!lastListing?.attributes?.createdAt ? moment(lastListing.attributes.createdAt).toISOString() : null;

        // number of times booked
        const { meta: { totalItems: totalBookings = 0 } } = responseSales?.data || { meta: {} };

        // number of times rented
        const { meta: { totalItems: totalRentings = 0 } } = responseOrders?.data || { meta: {} };

        // Total Number of Items Active
        const { totalListings: totalActiveListings } = activeListingsRes?.data || {};

        // Total Number of Items Inactive
        const { totalListings: totalClosedListings } = closeListingRes?.data || {};

        // Total Amount of Payouts & Total Amount of Booking Value
        const { data: { totalPayout, totalPayin } } = userTransactionTotals;

        if (typeof window !== 'undefined' && window.Intercom && intercomAppId) {
            const params = {
                app_id: intercomAppId,
                name: displayName,
                email,
                user_id: userId,
                created_at: createdAt,
                user_first_name: firstName,
                user_last_name: lastName,
                user_country: country,
                phone,
                number_of_listings: totalListings,
                number_of_times_booked: totalBookings,
                number_of_times_rented: totalRentings,
                number_of_active_listings: totalActiveListings,
                number_of_inactive_listings: totalClosedListings,
                first_listing_date: firstListingCreated,
                last_listing_date: lastListingCreated,
                first_booking_date: firstBookingDate,
                total_payout_amount: totalPayout.amount,
                total_booking_value: totalPayin.amount,
                base_currency: baseCurrency
            };
            console.log(params, 'intercom params');
            window.Intercom('boot', params);
        };
    } catch (error) {
        console.log(error, '&&& &&& => error');

        console.log('failed to initiate intercom for loggedin user')
    }
};