import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import {
  useMenu,
  useRange,
  useClearRefinements,
  useSearchBox,
  useGeoSearch,
} from 'react-instantsearch';
import {
  AlgoliaKeywordSearch,
  AlgoliaLocationSearch,
  Modal,
  OutsideClickHandler,
} from '../../../../components';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';
import { manageDisableScrolling } from '../../../../ducks/ui.duck';
import { normalizeString } from '../../../../util/data';
import css from './AlgoliaSearchSection.module.css';
import { createResourceLocatorString } from '../../../../util/routes';

const filters = [{ attribute: 'publicData.category', label: 'Category' }];

const moreFilters = [
  { attribute: 'publicData.access_to_rentals', label: 'Access' },
  { attribute: 'publicData.Condition_of_the_item', label: 'Condition' },
  {
    attribute: 'publicData.Trust_and_verification',
    label: 'Trust',
  },
  { attribute: 'publicData.cancelation_policy', label: 'Cancellation' },
  { attribute: 'publicData.reason_for_listing', label: 'Reason' },
  { attribute: 'publicData.security_deposit', label: 'Deposit' },
];

/**
 * Returns a new object without the specified properties.
 *
 * @param {Object} obj - The original object.
 * @param {string[]} keysToOmit - Array of property names to omit.
 * @returns {Object} A new object without the specified properties.
 */
function omitProperties(obj, keysToOmit) {
  // Create a new object excluding the properties in keysToOmit
  return Object.keys(obj).reduce((result, key) => {
    if (!keysToOmit.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

const SelectFilter = props => {
  const { label, attribute, onChange, initialValue } = props;
  const { items, refine } = useMenu({attribute});

  // Refine on initial render if value exists.
  useEffect(() => {
    if (typeof initialValue != 'undefined') {
      console.log(`refining: ${attribute} with ${initialValue}`);
      refine(initialValue);
    }
  }, [initialValue]);

  // Need to normalize labels.
  const updatedItems = items.map(i => ({
    ...i,
    label: normalizeString(i.label),
  }));

  const exactAttr = attribute?.split('.')[1];

  return (
    <Select
      onChange={v => {
        onChange({ attribute: exactAttr, value: v.value });
        // refine(v.value);
        // setLabel(v.value);
      }}
      // menuIsOpen={true}
      value={typeof initialValue == 'undefined' ? '' : initialValue}
      options={updatedItems}
      className={css.multiSelectBox}
      placeholder={typeof initialValue == 'undefined' ? label : initialValue}
      isSearchable={false}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: 'orangered',
          primary25: '#e27757b3',
          primary: '#E27757',
        },
      })}
    />
  );
};

const getInitialValue = (attribute, params) => {
  const exactAttr = !!attribute ? attribute.split('.')[1] : null;
  const value =
    exactAttr && typeof params == 'object' ? params[exactAttr] : null;
  return value;
};

const Filters = props => {
  const { filters = [], queryParams, onChange } = props;

  return (
    <div className={css.filters}>
      {filters.map(f => (
        <SelectFilter
          key={f.attribute}
          attribute={f.attribute}
          label={f.label}
          onChange={onChange}
          initialValue={getInitialValue(f.attribute, queryParams)}
        />
      ))}
    </div>
  );
};

const MoreFilters = props => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className={css.moreFiltersLabel}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        + More filters
      </button>

      <Modal
        className={css.moreFiltersModal}
        isOpen={isOpen}
        id="SearchPage"
        onManageDisableScrolling={manageDisableScrolling}
        onClose={() => setIsOpen(false)}
      >
        <div className={css.moreFilters}>
          {moreFilters.map(f => (
            <SelectFilter
              key={f.attribute}
              attribute={f.attribute}
              label={f.label}
              initialValue={getInitialValue(f.attribute, props.queryParams)}
              {...props}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

const unsetNumberInputValue = '';

function PrinceRangeInput(props) {
  const [isOpen, setIsOpen] = useState(false);

  const { start, range, canRefine, precision, refine } = useRange(props);
  const step = 1 / Math.pow(10, precision || 0);
  const values = {
    min:
      start[0] !== -Infinity && start[0] !== range.min
        ? start[0] / 100
        : unsetNumberInputValue,
    max:
      start[1] !== Infinity && start[1] !== range.max
        ? start[1] / 100
        : unsetNumberInputValue,
  };

  const [prevValues, setPrevValues] = useState(values);

  const [{ from, to }, setRange] = useState({
    from: values.min?.toString(),
    to: values.max?.toString(),
  });

  if (values.min !== prevValues.min || values.max !== prevValues.max) {
    setRange({ from: values.min?.toString(), to: values.max?.toString() });
    setPrevValues(values);
  }

  const minMax =
    range.min && range.max
      ? { min: range.min / 100, max: range.max / 100 }
      : { min: range.min, max: range.max };

  const toggleBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={css.priceContainer}>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <button className={css.priceButton} onClick={toggleBox}>
          Price
        </button>
        {isOpen && (
          <form
            className={css.priceForm}
            onSubmit={event => {
              event.preventDefault();
              const data = [
                from ? Number(from) * 100 : undefined,
                to ? Number(to) * 100 : undefined,
              ];
              refine(data);
            }}
          >
            <div>Price Range:</div>
            <div className={css.filterNumber}>
              <label>
                <input
                  type="number"
                  {...minMax}
                  value={stripLeadingZeroFromInput(
                    from || unsetNumberInputValue
                  )}
                  step={step}
                  placeholder={range.min?.toString()}
                  disabled={!canRefine}
                  onInput={({ currentTarget }) => {
                    const value = currentTarget.value;
                    // const valueInCents = Number(value || 0) * 100;
                    setRange({ from: value || unsetNumberInputValue, to });
                  }}
                />
              </label>
              <span> - </span>
              <label>
                <input
                  type="number"
                  {...minMax}
                  value={stripLeadingZeroFromInput(to || unsetNumberInputValue)}
                  step={step}
                  placeholder={range.max?.toString()}
                  disabled={!canRefine}
                  onInput={({ currentTarget }) => {
                    const value = currentTarget.value;
                    setRange({ from, to: value || unsetNumberInputValue });
                  }}
                />
              </label>
            </div>
            <button className={css.submitButton} type="submit">
              Apply
            </button>
          </form>
        )}
      </OutsideClickHandler>
    </div>
  );
}

function stripLeadingZeroFromInput(value = '') {
  return value.toString().replace(/^(0+)\d/, part => Number(part).toString());
}

const SearchSection = props => {
  const {
    currentUserLocationInfo,
    routesConfiguration,
    currentUser,
    history,
    currentUserCurrency,
    exchangeRates,
    config,
    intl,
    onBoundsChange,
    queryParams,
    selectedBounds,
    onActivateListing = { onActivateListing },
  } = props;

  const { keyword: qKeyword, bounds: qBounds, address: qAddress } =
    queryParams || {};

  const {
    canRefine: canClearRefine,
    refine: clearRefine,
  } = useClearRefinements(props);

  // Keyword Search
  const memoizedSearch = useCallback((query, search) => {
    search(query);
  }, []);

  const { refine: keywordRefine } = useSearchBox({
    queryHook: memoizedSearch,
  });

  // Location Search
  //   We want to refine results based on the selected location.
  const {
    refine: refineLocation,
    currentRefinement: currentLocationRefinement,
    clearMapRefinement,
  } = useGeoSearch();

  const handleRefineLocation = location => {
    if (location && location.address && location.bounds) {
      const updatedParams = { ...queryParams, ...location };
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routesConfiguration,
          {},
          updatedParams
        )
      );
    }
  };

  const handleRefineKeyword = (keyword = '') => {
    const updatedParams = { ...queryParams, keyword: keyword?.trim() || '' };
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routesConfiguration,
        {},
        updatedParams
      )
    );
  };

  const handleRefineFilters = filter => {
    const attribute = filter?.attribute;
    const value = filter?.value;
    if (!!attribute && !!value) {
      const params = { ...queryParams, [attribute]: value };
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routesConfiguration,
          {},
          params
        )
      );
    }
  };

  const handleClearFilters = () => {
    // Remove all the filters.
    const filteredParams = omitProperties(
      queryParams,
      [...filters, ...moreFilters].map(f => f.attribute.split('.')[1])
    );

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routesConfiguration,
        {},
        filteredParams
      )
    );

    if (canClearRefine) {
      clearRefine();
    }
  };

  return (
    <div>
      {/* Search Inputs */}
      <div className={css.searchInputs}>
        <AlgoliaKeywordSearch
          onSearchChange={useCallback(k => handleRefineKeyword(k), [
            queryParams,
          ])}
          refineKeyword={keywordRefine}
          initialValue={{ keyword: qKeyword }}
          history={history}
          currentUserCurrency={currentUserCurrency}
          currentUser={currentUser}
          exchangeRates={exchangeRates}
          routes={routesConfiguration}
          config={config}
          intl={intl}
          clearKeywordsClass={css.clearKeywords}
          autoFocus={true}
          selectedBounds={selectedBounds}
          onActivateListing={onActivateListing}
          searchInputText={intl.formatMessage({id:"AlgoliaKeywordSearch.placeholder"})}
        />
        <AlgoliaLocationSearch
          attribute={'publicData.location.address'}
          defaultLocation={currentUserLocationInfo}
          onRefineLocation={useCallback(l => handleRefineLocation(l), [
            queryParams,
          ])}
          refineLocation={refineLocation}
          currentLocationRefinement={currentLocationRefinement}
          clearMapRefinement={clearMapRefinement}
          initialValues={{ bounds: qBounds, address: qAddress }}
        />
      </div>

      {/* Filters */}
      <div className={css.filterWrapper}>
        <Filters
          filters={filters}
          onChange={useCallback(handleRefineFilters, [queryParams])}
          queryParams={queryParams}
        />
        <PrinceRangeInput attribute={'price.amount'} />
        <MoreFilters
          onChange={useCallback(handleRefineFilters, [queryParams])}
          queryParams={queryParams}
        />
        <button
          className={css.clearFilters}
          type="button"
          onClick={handleClearFilters}
        >
          <IconCard brand="clearFilters" />
        </button>
      </div>
    </div>
  );
};

export default SearchSection;
