import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { monthIdString } from '../../util/dates';
import { generateMonths } from '../../util/generators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { subUnitDivisors } from '../../config/settingsCurrency';
import { useConfiguration } from '../../context/configurationContext';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { convertLineItems, convertPrice, formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { getRentalDateFromLocalStorage, userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2 } from '../../components';
import PricePackages from './PricePackages/PricePackages';

import css from './OrderPanel.module.css';
import moment from 'moment';

const { Money } = sdkTypes;
// import { staticExchangeRates } from '../../config/configStripe';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

export const PER_DAY = 'perDayPrice';
export const PER_MINUTE = 'perMinutePrice';
export const PER_HOUR = 'perHourPrice';
export const TWO_DAYS = 'twoDaysPrice';
export const WEEKLY_PRICE = 'oneWeekPrice';
export const OVERNIGHT_PRICE = 'overnightPrice';
export const TWENTY_EIGHT_DAYS = 'twentyEightDaysPrice';
const DAY_PACKAGES = [PER_DAY, TWO_DAYS, WEEKLY_PRICE, OVERNIGHT_PRICE, TWENTY_EIGHT_DAYS];

export const sortPackages = (packages, defaultPackageKey) => {
  // Create a copy of the packages object
  const packagesCopy = { ...packages };

  // Extract the default package
  const defaultPackage = packagesCopy[defaultPackageKey];

  // Delete the default package from the copied object
  delete packagesCopy[defaultPackageKey];

  // Return the sorted packages
  return {
    ...(defaultPackage ? { [defaultPackageKey]: defaultPackage } : {}),
    ...(packagesCopy.perMinutePrice ? { perMinutePrice: packagesCopy.perMinutePrice } : {}),
    ...(packagesCopy.perHourPrice ? { perHourPrice: packagesCopy.perHourPrice } : {}),
    ...(packagesCopy.perDayPrice ? { perDayPrice: packagesCopy.perDayPrice } : {}),
    ...(packagesCopy.overnightPrice ? { overnightPrice: packagesCopy.overnightPrice } : {}),
    ...(packagesCopy.twoDaysPrice ? { twoDaysPrice: packagesCopy.twoDaysPrice } : {}),
    ...(packagesCopy.oneWeekPrice ? { oneWeekPrice: packagesCopy.oneWeekPrice } : {}),
    ...(packagesCopy.twentyEightDaysPrice ? { twentyEightDaysPrice: packagesCopy.twentyEightDaysPrice } : {})
  };
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location, selectedPackage) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true, package: selectedPackage })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location,
  selectedPackage
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location, selectedPackage);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
    currentUserCurrency,
    selectedPackage
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);

  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, currentUserCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ selectedPackage }} />
      </div>
    </div>
  );
};


const PromotionalTextMaybe = (props) => {
  const { selectedPackage, packages, intl } = props;

  const perDayPrice = packages?.[PER_DAY];
  const perWeekPrice = packages?.[WEEKLY_PRICE];
  const isPromotionalWeekPrice = perWeekPrice && perDayPrice && Math.floor(perWeekPrice.amount) === (Math.floor(perDayPrice.amount * 4));
  const showPromotionalText = selectedPackage === WEEKLY_PRICE && isPromotionalWeekPrice;
  const currency = perDayPrice?.currency;
  const subUnitDivisor = subUnitDivisors[currency];
  const formattedAmount = showPromotionalText ? intl.formatNumber((perDayPrice.amount * 3) / subUnitDivisor, { style: 'currency', currency }) : null;
  const promotionalText = intl.formatMessage({ id: 'OrderPanel.promotionalText' }, { formattedAmount });

  if (showPromotionalText) {
    return <p className={css.promotionalText}>{promotionalText}</p>
  }
  return null;
};


const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    showCartButton,
    currentUser,
    isCurrentUserListing,
    onAddToCart,
    addToCartInProgress,
    addToCartError,
    cartNotification,
    routeConfiguration
  } = props;
  const config = useConfiguration();

  const priceKeyFromSearch = parse(location.search).package;
  const isValidSearchPriceKey = [PER_DAY, PER_HOUR, WEEKLY_PRICE, PER_MINUTE, TWENTY_EIGHT_DAYS, TWO_DAYS, OVERNIGHT_PRICE].includes(priceKeyFromSearch);
  const publicData = listing?.attributes?.publicData || {};

  const defaultPriceKey = isValidSearchPriceKey
    ? priceKeyFromSearch
    : publicData?.defaultPriceKey
      ? publicData.defaultPriceKey
      : null;

  if (!defaultPriceKey) {
    return <div className={css.error}>Default price key is not not set</div>
  };

  const { currentUserCurrency } = useSelector(state => state.user);
  const { exchangeRates, fetRatesError, fetchRatesInProgress } = useSelector(state => state.exchangeRate);

  // Keeping per day package as default one.
  const [selectedPackage, setSelectedPackage] = useState(defaultPriceKey);

  useEffect(() => {
    setSelectedPackage(defaultPriceKey);
  }, [defaultPriceKey])

  const basePrice = listing.attributes.price;
  const prices = listing.attributes.publicData?.prices || { [defaultPriceKey]: { amount: basePrice.amount, currency: basePrice.currency } };
  const pricePackages = exchangeRates.length ? sortPackages(Object.keys(prices).reduce((packages, key) => {
    const pack = prices[key];
    const convertedPack = convertPrice(exchangeRates, pack.amount, pack.currency, currentUserCurrency)
    packages[key] = convertedPack;
    return packages;
  }, {}), defaultPriceKey) : null;

  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  // const price = listing?.attributes?.price;
  const currentPrice = pricePackages ? pricePackages[selectedPackage] : null;
  const price = !!currentPrice ? new Money(currentPrice.amount, currentPrice.currency) : null;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const exchangeRatesReady = !fetchRatesInProgress && exchangeRates.length;

  // Replace the static exchange rates with the real exchange rates.
  const convertedPrice = !!price && !!exchangeRatesReady ? convertPrice(exchangeRates, price.amount, price.currency, currentUserCurrency) : null;


  const showPriceMissing = isPaymentProcess && !price && exchangeRates.length;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  // const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const showInvalidCurrency = isPaymentProcess && convertedPrice?.currency !== currentUserCurrency;

  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const showFetchRatesError = fetRatesError && !exchangeRates?.length && !fetchRatesInProgress;

  const FetchExchangeRatesError = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.fetchExchangeRatesError" />
      </p>
    );
  };


  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  // const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const shouldHaveBookingTime = isBooking && [PER_HOUR, PER_MINUTE].includes(selectedPackage);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone && exchangeRatesReady;

  // const shouldHaveBookingDates =
  //   isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const shouldHaveBookingDates = isBooking && [PER_DAY, OVERNIGHT_PRICE, TWO_DAYS, WEEKLY_PRICE, TWENTY_EIGHT_DAYS].includes(selectedPackage);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone && exchangeRatesReady;


  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number' && exchangeRatesReady;

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const [bookingDateFormInitial, setBookingDateFormInitial] = useState({});
  const [bookingTimeFormInitial, setBookingTimeFormInitial] = useState({})
  let fetchTimeSlotInProgress = true;

  useEffect(() => {
    const { dates, selectedPackage: selectedStoredPackage } = getRentalDateFromLocalStorage(selectedPackage) || {};
    const { bookingStartDate, bookingEndDate, bookingStartTime, bookingEndTime } = dates || {};

    const monthlyTimeSlotsKeys = monthlyTimeSlots ? Object.keys(monthlyTimeSlots) : [];

    if (monthlyTimeSlotsKeys && monthlyTimeSlotsKeys.length) {
      const { timeSlots, fetchTimeSlotsInProgress } = monthlyTimeSlots[monthlyTimeSlotsKeys[0]];
      fetchTimeSlotInProgress = fetchTimeSlotsInProgress;

      if (bookingStartDate && bookingEndDate && (selectedStoredPackage === selectedPackage) && timeSlots && timeSlots.length && !fetchTimeSlotsInProgress) {
        const pickMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
          const monthId = monthIdString(date, timeZone);

          return monthlyTimeSlots?.[monthId]?.timeSlots || [];
        };

        const pickBookingMonthTimeSlots = (
          monthlyTimeSlots,
          startDate,
          endDate,
          timeZone
        ) => {
          // The generateMonths generator returns the first day of each month that is spanned
          // by the time range between start date and end date.
          const monthsInRange = generateMonths(startDate, endDate, timeZone);

          return monthsInRange.reduce((timeSlots, firstOfMonth) => {
            return [
              ...timeSlots,
              ...pickMonthlyTimeSlots(monthlyTimeSlots, firstOfMonth, timeZone),
            ];
          }, []);
        };
        const timeSlotsOnSelectedRange = pickBookingMonthTimeSlots(monthlyTimeSlots, bookingStartDate, bookingEndDate, timeZone);


        if (timeSlotsOnSelectedRange && timeSlotsOnSelectedRange.length) {
          const filteredTimeSlots = timeSlotsOnSelectedRange.filter(t => {
            const { start, end } = t.attributes || {};

            return (moment(start).tz(timeZone).unix() <= moment(bookingStartDate).tz(timeZone).unix() && moment(end).tz(timeZone).unix() >= moment(bookingEndDate).tz(timeZone).unix())
          });

          if (filteredTimeSlots && filteredTimeSlots.length) {
            if (DAY_PACKAGES.includes(selectedPackage)) {
              setBookingDateFormInitial({ bookingDates: { startDate: new Date(bookingStartDate), endDate: new Date(bookingEndDate) } });
            } else {
              setBookingTimeFormInitial({
                bookingStartDate: { date: new Date(bookingStartDate) },
                bookingEndDate: { date: new Date(bookingEndDate) },
                bookingStartTime,
                bookingEndTime
              });
            }
          }
        }
      } else {
        setBookingTimeFormInitial({})
        setBookingDateFormInitial({})
      }
    }
  }, [monthlyTimeSlots, selectedPackage]);

  // console.log(bookingDateFormInitial, '&&& &&& => initialValues');

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>

        <div className={css.priceAndPackages}>
          <PriceMaybe
            price={convertedPrice}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
            selectedCurrency={currentUserCurrency}
            selectedPackage={selectedPackage}
          />

          {!!pricePackages ? <PricePackages packages={pricePackages} intl={intl} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} currentUserCurrency={currentUserCurrency} /> : null}
        </div>


        <PromotionalTextMaybe selectedPackage={selectedPackage} packages={pricePackages} intl={intl} />

        <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
          </span>
        </div>

        {showPriceMissing ? (
          <PriceMissing />
        ) : showFetchRatesError ? (
          <FetchExchangeRatesError />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            initialValues={bookingTimeFormInitial}
            lineItemUnitType={lineItemUnitType}
            onSubmit={(e) => onSubmit({ ...e, package: selectedPackage })}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            listing={listing}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            selectedPackage={selectedPackage}
            showCartButton={showCartButton}
            currentUser={currentUser}
            isCurrentUserListing={isCurrentUserListing}
            onAddToCart={onAddToCart}
            addToCartInProgress={addToCartInProgress}
            addToCartError={addToCartError}
            cartNotification={cartNotification}
            authorId={author?.id?.uuid}
            config={config}
            history={history}
            routeConfiguration={routeConfiguration}
            autoFocused={!Object.keys(bookingTimeFormInitial || {}).length}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={(e) => onSubmit({ ...e, package: selectedPackage })}
            price={price}
            initialValues={bookingDateFormInitial}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            listing={listing}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            selectedPackage={selectedPackage}
            showCartButton={showCartButton}
            currentUser={currentUser}
            isCurrentUserListing={isCurrentUserListing}
            onAddToCart={onAddToCart}
            addToCartInProgress={addToCartInProgress}
            addToCartError={addToCartError}
            authorId={author?.id?.uuid}
            config={config}
            cartNotification={cartNotification}
            history={history}
            routeConfiguration={routeConfiguration}
            autoFocused={!Object.keys(bookingDateFormInitial || {}).length}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
            pickupEnabled={pickupEnabled && displayPickup}
            shippingEnabled={shippingEnabled && displayShipping}
            displayDeliveryMethod={displayPickup || displayShipping}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}

      </ModalInMobile>
      <div className={css.openOrderFormContainer}>
        {!!pricePackages ? <PricePackages packages={pricePackages} intl={intl} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} currentUserCurrency={currentUserCurrency} /> : null}
        <PromotionalTextMaybe selectedPackage={selectedPackage} packages={pricePackages} intl={intl} />
        <div className={css.openOrderForm}>
          <PriceMaybe
            price={convertedPrice}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
            selectedCurrency={currentUserCurrency}
            selectedPackage={selectedPackage}
          />

          {isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="OrderPanel.closedListingButtonText" />
            </div>
          ) : (
            <PrimaryButton
              onClick={handleSubmit(
                isOwnListing,
                isClosed,
                showInquiryForm,
                onSubmit,
                history,
                location,
                selectedPackage
              )}
              disabled={isOutOfStock}
            >
              {isBooking ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : isPurchase ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
              )}
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
