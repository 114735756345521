import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, useHits } from 'react-instantsearch';

import SearchSection from '../SearchSection/AlgoliaSearchSection';
import CustomHits from './Hits/Hits';

import Map from './Map/Map';
import CustomPagination from './Pagination/Pagination';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import mapIcon from '../../../../assets/map.png';

import css from './MainSearchContent.module.css';
import { parse } from '../../../../util/urlHelpers';

const { LatLngBounds, LatLng } = sdkTypes;

export function getCenterFromBounds(bounds) {
  if (bounds && bounds.ne && bounds.sw) {
    const north = bounds.ne.lat;
    const south = bounds.sw.lat;
    const east = bounds.ne.lng;
    const west = bounds.sw.lng;

    const centerLatitude = (north + south) / 2;
    const centerLongitude = (east + west) / 2;

    return { lat: centerLatitude, lng: centerLongitude };
  }
  return null;
}

export const MAX_HITS = 150;

const ALGOLIA_CLIENT_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY;
const ALGOLIA_LISTINGS_INDEX = process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX;

export const PRODUCT_CLICKED_EVENT = 'Product Clicked';
export const STORE_CLICKED_EVENT = 'Store Clicked';
export const coverEarthRadius = 20037500; // Radius to cover the entire earth's circumference from a given point in meters.

const searchClient = algoliasearch(ALGOLIA_CLIENT_ID, ALGOLIA_API_KEY);

const ResultsInfo = props => {
  const { selectedArea } = props;
  const { results } = useHits(props);
  const totalResults = results?.nbHits || 0;


  //As we are supposed to never show less and more than
  //150 records everytime.
  const resultsMsg = '150 results';

  // Show the total number of rental opportunities only
  // if there is more than 100 items.
  const opportunitiesMsg =
    totalResults > 100 && !!selectedArea
      ? `${totalResults} Rental opportunities in ${selectedArea}.`
      : totalResults > 100
      ? `${totalResults} Rental opportunities.`
      : `1000’s of Rental Opportunities!`;

  return (
    <div className={css.searchResultText}>
      {/* <span>{`${totalResults} results`}</span> */}
      <span>{resultsMsg}</span>
      <span>{opportunitiesMsg}</span>
    </div>
  );
};

export const LOCAL_STORAGE_MAP_KEY = 'mapPreference';

export default function MainSearchContent(props) {
  const {
    currentUserLocationInfo,
    currentUserCurrency,
    currentUser,
    exchangeRates,
    config,
    history,
    routesConfiguration,
    onActivateListing,
    location,
    intl,
  } = props;

  const search = props.location?.search || {};

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const defaultMapPreference =
    typeof window != 'undefined' && window.localStorage
      ? window.localStorage.getItem(LOCAL_STORAGE_MAP_KEY)
      : null;
  const preference = defaultMapPreference
    ? JSON.parse(defaultMapPreference).open
    : false;

  const [showMap, setShowMap] = useState(preference);
  // const [selectedBounds, setSelectedBounds] = useState(null);

  const defaultOrigin = props.currentUserLocationInfo?.origin;
  const { lng, lat } = defaultOrigin || {};
  const { bounds: qBounds, address: qAddress } = queryParams || {};

  const kmToDeg = 50 / 111;
  const center = lng && lat ? [lng, lat] : [-75.6972, 45.4215]; // Ottawa's center coordinates
  const bounds = [
    new LatLng(center[1] - kmToDeg, center[0] - kmToDeg), //southwest
    new LatLng(center[1] + kmToDeg, center[0] + kmToDeg), //northeast
  ];
  const defaultBounds = new LatLngBounds(bounds[1], bounds[0]);

  const selectedBounds = qBounds ? qBounds : defaultBounds;

  const selectedArea = !!qAddress ? qAddress : currentUserLocationInfo?.city;

  const toggleMapHandler = bool => {
    setShowMap(bool);
    if (typeof window != 'undefined' && window.localStorage) {
      window.localStorage.setItem(
        LOCAL_STORAGE_MAP_KEY,
        JSON.stringify({ open: bool })
      );
    }
  };

  return (
    <main>
      <InstantSearch
        searchClient={searchClient}
        indexName={ALGOLIA_LISTINGS_INDEX}
        insights={true}
      >
        <Configure
         hitsPerPage={MAX_HITS} 
         removeWordsIfNoResults="allOptional"
         />
        <div className={css.mainSearchContent}>
          <div className={css.leftContent}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ResultsInfo selectedArea={selectedArea} />
              {/* Show map */}
              {!showMap ? (
                <button
                  className={css.mapButton}
                  type="button"
                  onClick={() => toggleMapHandler(!showMap)}
                >
                  <img src={mapIcon} alt="map" />
                </button>
              ) : null}
            </div>
            <SearchSection
              currentUserLocationInfo={currentUserLocationInfo}
              currentUserCurrency={currentUserCurrency}
              currentUser={currentUser}
              exchangeRates={exchangeRates}
              intl={intl}
              setShowMap={setShowMap}
              showMap={showMap}
              config={config}
              history={history}
              routesConfiguration={routesConfiguration}
              queryParams={queryParams}
              selectedBounds={selectedBounds}
              onActivateListing={onActivateListing}
            />
            <CustomHits
              history={history}
              routesConfiguration={routesConfiguration}
              selectedBounds={selectedBounds}
              onActivateListing={onActivateListing}
            />

            {/* <CustomPagination /> */}
          </div>
          <Map
            className={css.map}
            currentUserCurrency={currentUserCurrency}
            intl={intl}
            location={location}
            exchangeRates={exchangeRates}
            showMap={showMap}
            selectedBounds={selectedBounds}
            onClose={() => toggleMapHandler(false)}
            activeListingId={props.activeListingId}
          />
        </div>
      </InstantSearch>
    </main>
  );
}
