import React, { useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { useGeoSearch, useSearchBox } from 'react-instantsearch';
import SearchMap from '../../../SearchMap/SearchMap';
import { types as sdkTypes } from '../../../../../util/sdkLoader';
import css from './Map.module.css';

const { LatLng, Money, UUID } = sdkTypes;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MODAL_BREAKPOINT = 768;
const isWindowDefined = typeof window !== 'undefined';
const isDesktopLayout = isWindowDefined && window.innerWidth > MODAL_BREAKPOINT;

const transformItemsToOriginalListings = items => {
  return items.reduce((listings, item) => {
    const { _geoloc, price, ...rest } = item;
    if (price && price.amount && price.currency) {
      const listing = {
        id: new UUID(rest.objectID),
        type: 'listing',
        attributes: {
          geolocation: { ..._geoloc },
          price: new Money(price.amount, price.currency),
          ...rest,
        },
      };
      return [...listings, listing];
    } else {
      return listings;
    }
  }, []);
};

export default function Map(props) {
  const {
    currentUserCurrency,
    exchangeRates,
    intl,
    defaultOrigin,
    location,
    showMap,
    onClose,
    selectedBounds,
    activeListingId
  } = props;
  const { lat, lng } = defaultOrigin || {};
  const { query, refine: refineQuery } = useSearchBox();
  const {
    items,
    refine: refineItems,
    currentRefinement,
    clearMapRefinement,
  } = useGeoSearch();
  const [previousQuery, setPreviousQuery] = useState(query);
  const [skipViewEffect, setSkipViewEffect] = useState(false);
  const [map, setMap] = useState(null);
  const [mapRefineTimer, setMapRefineTimer] = useState(null);

  const latLng = new LatLng(lat, lng);

  // Update map bounds and refine search on map move
  const onViewChange = (viewportBoundsChanged, data) => {
    setSkipViewEffect(true);
    if (query.length > 0) {
      refineQuery('');
    }
    const { viewportBounds } = data || {};
    if (
      viewportBoundsChanged &&
      viewportBounds &&
      viewportBounds.ne &&
      viewportBounds.sw
    ) {
      if (mapRefineTimer) {
        clearTimeout(mapRefineTimer);
      }
      const timer = setTimeout(() => {
        refineItems({
          northEast: viewportBounds.ne,
          southWest: viewportBounds.sw,
        });
      }, 1000);
      setMapRefineTimer(timer);
    }
  };

  const listings = transformItemsToOriginalListings(items);

 
  return (
    <div className={css.mapWrapper}>
      {showMap || isDesktopLayout ? (
        <SearchMap
          reusableContainerClassName={css.map}
          activeListingId={activeListingId}
          bounds={selectedBounds}
          center={latLng}
          isSearchMapOpenOnMobile={false}
          location={location}
          listings={listings || []}
          onMapMoveEnd={() => {
            // no need to do anything as we have
            // disabled the intraction with the map
            // and managing geosearch outside the map.
          }}
          onCloseAsModal={() => {
            // onManageDisableScrolling("SearchPage.map", false);
          }}
          messages={intl.messages}
          selectedCurrency={currentUserCurrency}
          exchangeRates={exchangeRates}
          setMap={setMap}
          showCloseButton={!isDesktopLayout}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}
