import * as stripe from './configStripe';
import * as listing from './configListing';
import * as search from './configSearch';
import * as maps from './configMaps';
import * as branding from './configBranding';
import * as layout from './configLayout';
import * as analytics from './configAnalytics';



export const signupCountries = [
  { country: "Afghanistan", code: "AF", currency: "AFN" },
  { country: "Albania", code: "AL", currency: "ALL" },
  { country: "Algeria", code: "DZ", currency: "DZD" },
  { country: "Andorra", code: "AD", currency: "EUR" },
  { country: "Angola", code: "AO", currency: "AOA" },
  { country: "Antigua and Barbuda", code: "AG", currency: "XCD" },
  { country: "Argentina", code: "AR", currency: "ARS" },
  { country: "Armenia", code: "AM", currency: "AMD" },
  { country: "Australia", code: "AU", currency: "AUD" },
  { country: "Austria", code: "AT", currency: "EUR" },
  { country: "Azerbaijan", code: "AZ", currency: "AZN" },
  { country: "Bahamas", code: "BS", currency: "BSD" },
  { country: "Bahrain", code: "BH", currency: "BHD" },
  { country: "Bangladesh", code: "BD", currency: "BDT" },
  { country: "Barbados", code: "BB", currency: "BBD" },
  { country: "Belarus", code: "BY", currency: "BYN" },
  { country: "Belgium", code: "BE", currency: "EUR" },
  { country: "Belize", code: "BZ", currency: "BZD" },
  { country: "Benin", code: "BJ", currency: "XOF" },
  { country: "Bhutan", code: "BT", currency: "BTN" },
  { country: "Bolivia", code: "BO", currency: "BOB" },
  { country: "Bosnia and Herzegovina", code: "BA", currency: "BAM" },
  { country: "Botswana", code: "BW", currency: "BWP" },
  { country: "Brazil", code: "BR", currency: "BRL" },
  { country: "Brunei", code: "BN", currency: "BND" },
  { country: "Bulgaria", code: "BG", currency: "BGN" },
  { country: "Burkina Faso", code: "BF", currency: "XOF" },
  { country: "Burundi", code: "BI", currency: "BIF" },
  { country: "Cambodia", code: "KH", currency: "KHR" },
  { country: "Cameroon", code: "CM", currency: "XAF" },
  { country: "Canada", code: "CA", currency: "CAD" },
  { country: "Cape Verde", code: "CV", currency: "CVE" },
  { country: "Central African Republic", code: "CF", currency: "XAF" },
  { country: "Chad", code: "TD", currency: "XAF" },
  { country: "Chile", code: "CL", currency: "CLP" },
  { country: "Colombia", code: "CO", currency: "COP" },
  { country: "Comoros", code: "KM", currency: "KMF" },
  { country: "Congo (Brazzaville)", code: "CG", currency: "XAF" },
  { country: "Congo (Kinshasa)", code: "CD", currency: "CDF" },
  { country: "Costa Rica", code: "CR", currency: "CRC" },
  { country: "Croatia", code: "HR", currency: "HRK" },
  { country: "Cyprus", code: "CY", currency: "EUR" },
  { country: "Czech Republic", code: "CZ", currency: "CZK" },
  { country: "Denmark", code: "DK", currency: "DKK" },
  { country: "Djibouti", code: "DJ", currency: "DJF" },
  { country: "Dominica", code: "DM", currency: "XCD" },
  { country: "Dominican Republic", code: "DO", currency: "DOP" },
  { country: "East Timor", code: "TL", currency: "USD" },
  { country: "Ecuador", code: "EC", currency: "USD" },
  { country: "Egypt", code: "EG", currency: "EGP" },
  { country: "El Salvador", code: "SV", currency: "USD" },
  { country: "Equatorial Guinea", code: "GQ", currency: "XAF" },
  { country: "Eritrea", code: "ER", currency: "ERN" },
  { country: "Estonia", code: "EE", currency: "EUR" },
  { country: "Eswatini", code: "SZ", currency: "SZL" },
  { country: "Ethiopia", code: "ET", currency: "ETB" },
  { country: "Fiji", code: "FJ", currency: "FJD" },
  { country: "Finland", code: "FI", currency: "EUR" },
  { country: "France", code: "FR", currency: "EUR" },
  { country: "Gabon", code: "GA", currency: "XAF" },
  { country: "Gambia", code: "GM", currency: "GMD" },
  { country: "Georgia", code: "GE", currency: "GEL" },
  { country: "Germany", code: "DE", currency: "EUR" },
  { country: "Ghana", code: "GH", currency: "GHS" },
  { country: "Greece", code: "GR", currency: "EUR" },
  { country: "Grenada", code: "GD", currency: "XCD" },
  { country: "Guatemala", code: "GT", currency: "GTQ" },
  { country: "Guinea", code: "GN", currency: "GNF" },
  { country: "Guinea-Bissau", code: "GW", currency: "XOF" },
  { country: "Guyana", code: "GY", currency: "GYD" },
  { country: "Haiti", code: "HT", currency: "HTG" },
  { country: "Honduras", code: "HN", currency: "HNL" },
  { country: "Hungary", code: "HU", currency: "HUF" },
  { country: "Iceland", code: "IS", currency: "ISK" },
  { country: "India", code: "IN", currency: "INR" },
  { country: "Indonesia", code: "ID", currency: "IDR" },
  { country: "Iran", code: "IR", currency: "IRR" },
  { country: "Iraq", code: "IQ", currency: "IQD" },
  { country: "Ireland", code: "IE", currency: "EUR" },
  { country: "Israel", code: "IL", currency: "ILS" },
  { country: "Italy", code: "IT", currency: "EUR" },
  { country: "Jamaica", code: "JM", currency: "JMD" },
  { country: "Japan", code: "JP", currency: "JPY" },
  { country: "Jordan", code: "JO", currency: "JOD" },
  { country: "Kazakhstan", code: "KZ", currency: "KZT" },
  { country: "Kenya", code: "KE", currency: "KES" },
  { country: "Kiribati", code: "KI", currency: "AUD" },
  { country: "Korea (North)", code: "KP", currency: "KPW" },
  { country: "Korea (South)", code: "KR", currency: "KRW" },
  { country: "Kosovo", code: "XK", currency: "EUR" },
  { country: "Kuwait", code: "KW", currency: "KWD" },
  { country: "Kyrgyzstan", code: "KG", currency: "KGS" },
  { country: "Laos", code: "LA", currency: "LAK" },
  { country: "Latvia", code: "LV", currency: "EUR" },
  { country: "Lebanon", code: "LB", currency: "LBP" },
  { country: "Lesotho", code: "LS", currency: "LSL" },
  { country: "Liberia", code: "LR", currency: "LRD" },
  { country: "Libya", code: "LY", currency: "LYD" },
  { country: "Liechtenstein", code: "LI", currency: "CHF" },
  { country: "Lithuania", code: "LT", currency: "EUR" },
  { country: "Luxembourg", code: "LU", currency: "EUR" },
  { country: "Madagascar", code: "MG", currency: "MGA" },
  { country: "Malawi", code: "MW", currency: "MWK" },
  { country: "Malaysia", code: "MY", currency: "MYR" },
  { country: "Maldives", code: "MV", currency: "MVR" },
  { country: "Mali", code: "ML", currency: "XOF" },
  { country: "Malta", code: "MT", currency: "EUR" },
  { country: "Marshall Islands", code: "MH", currency: "USD" },
  { country: "Mauritania", code: "MR", currency: "MRU" },
  { country: "Mauritius", code: "MU", currency: "MUR" },
  { country: "Mexico", code: "MX", currency: "MXN" },
  { country: "Micronesia", code: "FM", currency: "USD" },
  { country: "Moldova", code: "MD", currency: "MDL" },
  { country: "Monaco", code: "MC", currency: "EUR" },
  { country: "Mongolia", code: "MN", currency: "MNT" },
  { country: "Montenegro", code: "ME", currency: "EUR" },
  { country: "Morocco", code: "MA", currency: "MAD" },
  { country: "Mozambique", code: "MZ", currency: "MZN" },
  { country: "Myanmar", code: "MM", currency: "MMK" },
  { country: "Namibia", code: "NA", currency: "NAD" },
  { country: "Nauru", code: "NR", currency: "AUD" },
  { country: "Nepal", code: "NP", currency: "NPR" },
  { country: "Netherlands", code: "NL", currency: "EUR" },
  { country: "New Zealand", code: "NZ", currency: "NZD" },
  { country: "Nicaragua", code: "NI", currency: "NIO" },
  { country: "Niger", code: "NE", currency: "XOF" },
  { country: "Nigeria", code: "NG", currency: "NGN" },
  { country: "North Macedonia", code: "MK", currency: "MKD" },
  { country: "Norway", code: "NO", currency: "NOK" },
  { country: "Oman", code: "OM", currency: "OMR" },
  { country: "Pakistan", code: "PK", currency: "PKR" },
  { country: "Palau", code: "PW", currency: "USD" },
  { country: "Palestine", code: "PS", currency: "ILS" },
  { country: "Panama", code: "PA", currency: "PAB" },
  { country: "Papua New Guinea", code: "PG", currency: "PGK" },
  { country: "Paraguay", code: "PY", currency: "PYG" },
  { country: "Peru", code: "PE", currency: "PEN" },
  { country: "Philippines", code: "PH", currency: "PHP" },
  { country: "Poland", code: "PL", currency: "PLN" },
  { country: "Portugal", code: "PT", currency: "EUR" },
  { country: "Qatar", code: "QA", currency: "QAR" },
  { country: "Romania", code: "RO", currency: "RON" },
  { country: "Rwanda", code: "RW", currency: "RWF" },
  { country: "Saint Kitts and Nevis", code: "KN", currency: "XCD" },
  { country: "Saint Lucia", code: "LC", currency: "XCD" },
  { country: "Saint Vincent and the Grenadines", code: "VC", currency: "XCD" },
  { country: "Samoa", code: "WS", currency: "WST" },
  { country: "San Marino", code: "SM", currency: "EUR" },
  { country: "Sao Tome and Principe", code: "ST", currency: "STD" },
  { country: "Saudi Arabia", code: "SA", currency: "SAR" },
  { country: "Senegal", code: "SN", currency: "XOF" },
  { country: "Serbia", code: "RS", currency: "RSD" },
  { country: "Seychelles", code: "SC", currency: "SCR" },
  { country: "Sierra Leone", code: "SL", currency: "SLL" },
  { country: "Singapore", code: "SG", currency: "SGD" },
  { country: "Slovakia", code: "SK", currency: "EUR" },
  { country: "Slovenia", code: "SI", currency: "EUR" },
  { country: "Solomon Islands", code: "SB", currency: "SBD" },
  { country: "Somalia", code: "SO", currency: "SOS" },
  { country: "South Africa", code: "ZA", currency: "ZAR" },
  { country: "South Sudan", code: "SS", currency: "SSP" },
  { country: "Spain", code: "ES", currency: "EUR" },
  { country: "Sri Lanka", code: "LK", currency: "LKR" },
  { country: "Sudan", code: "SD", currency: "SDG" },
  { country: "Suriname", code: "SR", currency: "SRD" },
  { country: "Sweden", code: "SE", currency: "SEK" },
  { country: "Switzerland", code: "CH", currency: "CHF" },
  { country: "Syria", code: "SY", currency: "SYP" },
  { country: "Taiwan", code: "TW", currency: "TWD" },
  { country: "Tajikistan", code: "TJ", currency: "TJS" },
  { country: "Tanzania", code: "TZ", currency: "TZS" },
  { country: "Thailand", code: "TH", currency: "THB" },
  { country: "Togo", code: "TG", currency: "XOF" },
  { country: "Tonga", code: "TO", currency: "TOP" },
  { country: "Trinidad and Tobago", code: "TT", currency: "TTD" },
  { country: "Tunisia", code: "TN", currency: "TND" },
  { country: "Turkey", code: "TR", currency: "TRY" },
  { country: "Turkmenistan", code: "TM", currency: "TMT" },
  { country: "Tuvalu", code: "TV", currency: "AUD" },
  { country: "Uganda", code: "UG", currency: "UGX" },
  { country: "Ukraine", code: "UA", currency: "UAH" },
  { country: "United Arab Emirates", code: "AE", currency: "AED" },
  { country: "United Kingdom", code: "GB", currency: "GBP" },
  { country: "United States", code: "US", currency: "USD" },
  { country: "Uruguay", code: "UY", currency: "UYU" },
  { country: "Uzbekistan", code: "UZ", currency: "UZS" },
  { country: "Vanuatu", code: "VU", currency: "VUV" },
  { country: "Venezuela", code: "VE", currency: "VES" },
  { country: "Vietnam", code: "VN", currency: "VND" },
  { country: "Yemen", code: "YE", currency: "YER" },
  { country: "Zambia", code: "ZM", currency: "ZMW" },
  { country: "Zimbabwe", code: "ZW", currency: "ZWL" }
];




// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets to this file.
const defaultConfig = {
  // Marketplace root url is the root or domain, where this app is currently running
  // It is needed in social media sharing and SEO optimization purposes and SSO etc.
  // It should be in environment variables (on localhost 'yarn run config' or edit .env file)
  marketplaceRootURL: process.env.REACT_APP_MARKETPLACE_ROOT_URL,

  // Marketplace currency.
  // The currency used in the Marketplace must be in ISO 4217 currency code. For example USD, EUR, CAD, AUD, etc. The default value is USD.
  // It should match one of the currencies listed in currencySettings.js
  // Note: The currency comes from localization asset nowadays by default.
  //       To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeCurrency func)
  currency: 'CAD',

  // Listing minimum price in currency sub units, e.g. cents.
  // 0 means no restriction to the price
  // Note 1: The listingMinimumPriceSubUnits comes from transactionSize asset nowadays by default.
  //         To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeConfig func)
  // Note 2: Stripe does have minimum fee that depends on country, currency, etc.
  listingMinimumPriceSubUnits: 500,

  // Marketplace name is needed for microcopy (aka marketplace texts) and in meta tags (bots and social media sharing reads those)
  marketplaceName: process.env.REACT_APP_MARKETPLACE_NAME || 'Biketribe',

  // Modify Stripe configuration in configStripe.js
  // - picks REACT_APP_STRIPE_PUBLISHABLE_KEY from environment variables
  // - dayCountAvailableForBooking: Stripe can hold payments only limited time on Connect Account
  //                                This adds some restriction for bookings (payouts vs long bookings)
  // - defaultMCC: sets Default Merchant Category Code
  // - supportedCountries
  stripe,

  signupCountries,
  // Modify listing extended data and listing type in configListing.js
  listing,
  // Modify search settings data in configSearch.js
  search,
  // Modify settings for map providers in configMaps.js
  // This includes also default locations for location search
  maps,
  // Modify branding configs in configBranding.js
  branding,
  // Modify layout configs in configLayout.js
  layout,
  // Modify analytics configs in configAnalytics.js
  // By default, it doesn't add anything.
  analytics,

  // Note: Facebook app id is used for Facebook login, but it is also used for tracking:
  // Facebook counts shares with app or page associated by this id
  // Currently it is unset, but you can read more about fb:app_id from
  // https://developers.facebook.com/docs/sharing/webmasters#basic
  // You should create one to track social sharing in Facebook
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,

  // If you want to change the language, remember to also change the
  // locale data and the messages in the app.js file.
  // Note: The localization comes from localization asset nowadays by default.
  //       To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeLocalizations func)
  localization: {
    locale: 'en-US',
    // First day of week
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo#return_value
    // 1: Monday
    // ...
    // 6: Saturday
    // 7: Sunday
    firstDayOfWeek: 1,
  },

  // CDN assets for the app. Configurable through Sharetribe Console.
  // Note 1: The path must match the path defined in Asset Delivery API
  // Note 2: If you customize the app and rely on custom code instead of a config assets,
  //         you should remove the unnecessary config asset from this list.
  appCdnAssets: {
    translations: '/content/translations.json',
    footer: '/content/footer.json',
    topbar: '/content/top-bar.json',
    branding: '/design/branding.json',
    layout: '/design/layout.json',
    listingTypes: '/listings/listing-types.json',
    listingFields: '/listings/listing-fields.json',
    search: '/listings/listing-search.json',
    transactionSize: '/transactions/minimum-transaction-size.json',
    analytics: '/integrations/analytics.json',
    googleSearchConsole: '/integrations/google-search-console.json',
    maps: '/integrations/map.json',
    // These assets are not yet editable through Console.
    // However, Sharetribe onboarding might generate them.
    // You could still rely on built-in variables and comment these out.
    localization: '/general/localization.json',
    // NOTE: we don't fetch commission configuration here but on the server-side
  },

  // Optional
  // Online presence of the same organization:
  // Facebook page is used in SEO schema (http://schema.org/Organization)
  siteFacebookPage: null, // e.g. '@sharetribe',
  // Instagram page is used in SEO schema (http://schema.org/Organization)
  siteInstagramPage: null, // e.g. 'https://www.instagram.com/sharetribe/',
  // Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
  siteTwitterHandle: null, // e.g. 'https://www.facebook.com/Sharetribe/',

  // Optional
  // This creates meta tag for Google Search Console verification
  // I.e. <meta name=“google-site-verification” content=“GOOGLE_SITE_VERIFICATION_TOKEN”/>
  // Note: The googleSearchConsole comes from googleSearchConsole asset nowadays by default.
  //       To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeConfig func)
  googleSearchConsole: {
    googleSiteVerification: null, // Add google-site-verification token as a string
  },

  // Optional
  // Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
  // Note: Google doesn't recognize this:
  // https://developers.google.com/search/docs/advanced/structured-data/logo#structured-data-type-definitions
  address: {
    addressCountry: null, // 'FI',
    addressRegion: null, // 'Helsinki',
    postalCode: null, // '00130',
    streetAddress: null, // 'Erottajankatu 19 B',
  },
};

export default defaultConfig;
