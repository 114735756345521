import {
  types as sdkTypes,
  createImageVariantConfig,
} from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchExchangeRates } from '../../ducks/exchangeRates.duck';
import { getUserAchievements, updateAlgoliaData } from '../../util/api';
import { generateAchievementReviews } from '../../util/reviews';

const { UUID } = sdkTypes;

export const SET_INITIAL_STATE = 'app/StoreProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/StoreProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/StoreProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/StoreProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST =
  'app/StoreProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS =
  'app/StoreProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/StoreoPfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST =
  'app/StoreProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS =
  'app/StoreProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/StoreProfilePage/QUERY_REVIEWS_ERROR';

export const UPDATE_ORDER_ERROR = 'app/StoreProfilePage/UPDATE_ORDER_ERROR';

const initialState = {
  userId: null,
  userShowError: null,
  userShowInProgress: false,
  reviews: [],
  totalReviews: 0,
  queryReviewsError: null,
  userListingRefs: [],
  totalListings: 0,
  queryListingsError: null,
  updateOrderError: null,
};

export default function storeProfilePageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return {
        ...state,
        userShowInProgress: true,
        userShowError: null,
        userId: payload.userId,
      };
    case SHOW_USER_SUCCESS:
      return { ...state, userShowInProgress: false };
    case SHOW_USER_ERROR:
      return { ...state, userShowInProgress: false, userShowError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: payload.reviews,
        totalReviews: payload.totalReviews,
      };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs:
          payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        userListingRefs: payload.listingRefs,
        totalListings: payload.totalListings,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case UPDATE_ORDER_ERROR:
      return { ...state, updateOrderError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = ({ reviews, totalReviews }) => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: { reviews, totalReviews },
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = ({ listingRefs, totalListings }) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, totalListings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const updateOrderError = e => ({
  type: UPDATE_ORDER_ERROR,
  error: true,
  payload: e,
});

export const queryUserListings = (userId, config) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': [
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const totalListings = response.data.meta.totalItems;
      const listingRefs = response.data.data.map(({ id, type }) => ({
        id,
        type,
      }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess({ listingRefs, totalListings }));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const totalReviews = response.data.meta.totalItems;
      const reviews = denormalisedResponseEntities(response);

      // Generate Custom reviews based on user's achievements.
      return getUserAchievements(userId.uuid).then(({ data }) => {
        const {
          emailVerified,
          stripeConnected,
          publishedListings,
          displayName,
        } = data;
        const achievementReviews = generateAchievementReviews(
          emailVerified,
          stripeConnected,
          publishedListings,
          displayName
        );
        dispatch(
          queryReviewsSuccess({
            reviews: [...achievementReviews, ...reviews],
            totalReviews: totalReviews + achievementReviews.length,
          })
        );
      });
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

// ================ Thunks ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        'variants.scaled-small',
      ],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => {
      console.log(e, '&&& &&& => e');
      dispatch(showUserError(storableError(e)));
    });
};

export const updateRecordsOrder = data => (dispatch, getState, sdk) => {

  // Update records order to Flex Promises
  const updateRecordsToFlex = data.map(d =>
    sdk.ownListings.update({
      id: d.objectID,
      publicData: {
        order: d.order,
      },
    })
  );

  // Update records order to Algolia Promise
  const updateRecordsToAlgolia = updateAlgoliaData({
    indexName: process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX,
    data,
  });

  Promise.all([...updateRecordsToFlex, updateRecordsToAlgolia]).catch(e => {
    console.log(e, '&&& &&& => e');
    dispatch(updateOrderError(storableError(e)));
    setTimeout(() => dispatch(updateOrderError(null)), 3000);
  });
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  const userId = new UUID(params.id);
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, config)),
    dispatch(queryUserReviews(userId)),
  ]).then(response => {
    let { currentUser } = getState().user;

    if (!currentUser || (currentUser && !currentUser.id)) {
      currentUser = dispatch(fetchCurrentUser());
    }

    // Fetch exchange rates, do not fetch if already exists.
    const { exchangeRates } = getState().exchangeRate;

    if (
      typeof window != 'undefined' &&
      exchangeRates &&
      exchangeRates.length == 0
    ) {
      dispatch(fetchExchangeRates());
    }

    return response;
  });
};
