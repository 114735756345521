import React, { memo, useEffect, useState } from 'react';
import { useHits, useInstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch';
import { useSelector } from 'react-redux';
import { H4, Loader } from '../../../../../components';
import ListCard from '../Card/ListCard';
import {
  coverEarthRadius,
  getCenterFromBounds,
  MAX_HITS,
  PRODUCT_CLICKED_EVENT,
} from '../MainSearchContent';
import Pagination from '../Pagination/Pagination';
import { updateAlgoliaViews } from '../../../../../util/api';
import css from './Hits.module.css';

const ALGOLIA_CLIENT_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY;
const ALGOLIA_LISTINGS_INDEX = process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX;

const searchClient = algoliasearch(ALGOLIA_CLIENT_ID, ALGOLIA_API_KEY);

const index = searchClient.initIndex(ALGOLIA_LISTINGS_INDEX);

const searchHits = ({ perPage, query, filters, aroundLatLng, radius }) =>
  index.search(query, { hitsPerPage: perPage, filters, aroundLatLng, radius });

function removeDuplicates(hits) {
  const uniqueHits = new Map();

  hits.forEach(h => {
    // Use the product id as the key
    if (!uniqueHits.has(h.objectID)) {
      uniqueHits.set(h.objectID, h);
    }
  });

  // Convert the map values back to an array
  return Array.from(uniqueHits.values());
}

const CustomHits = props => {
  const { currentUserCurrency, currentUser } = useSelector(state => state.user);
  const { exchangeRates } = useSelector(state => state.exchangeRate);
  const { status, error, indexUiState } = useInstantSearch({
    catchError: true,
  });

  const { results, items, banner, nbHits, sendEvent } = useHits(props);

  // console.log(useInstantSearch(), 'instant');

  const [hitsTimer, setHitsTimer] = useState(null);
  const [hitsToRender, setHitsToRender] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 24;
  const totalPages = Math.ceil(hitsToRender.length / productsPerPage);

  const handlePageChange = pageNumber => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const renderableHits = hitsToRender.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const queryID = results.queryID;

  const userTokenMaybe =
    currentUser && currentUser.id ? { userToken: currentUser.id.uuid } : {};
  // console.log(items, 'items')

  // if (status === 'stalled' || status === 'loading') {
  //   return <Loader />;
  // }

  const resultsLength = results.hits.length;
  const hitsToFetch = MAX_HITS - resultsLength;
  const indexUiStateInitialized = Object.values(indexUiState).length;
  const center = getCenterFromBounds(props.selectedBounds);
  const readyToFetch = indexUiStateInitialized && status === 'idle';

  useEffect(() => {
    let timer;
    if (indexUiStateInitialized) {
      // See if there are the required number of hits or not
      // in index.
      if (hitsTimer) {
        clearTimeout(hitsTimer);
      }

      timer = setTimeout(() => {
        const aroundLatLng = `${center.lat}, ${center.lng}`;
        const radius = coverEarthRadius * 1000;

        searchHits({
          perPage: 0,
          query: '',
          aroundLatLng,
          aroundRadius: radius,
        }).then(({ nbHits: availableRecords }) => {
          if (
            availableRecords >= MAX_HITS &&
            hitsToFetch > 0 &&
            readyToFetch &&
            (typeof nbHits == 'undefined' || nbHits < MAX_HITS)
          ) {
            const { menu = {}, query = '' } = indexUiState || {};
            const searchedCategory = menu['publicData.category'];
            const filters = !!searchedCategory
              ? `category:${searchedCategory}`
              : '';

            // Fetching additional results equal to the current results
            // length will ensure the minimum records requirement.
            // If the following query fetches the some same results
            // the duplicates are removed, but the additional fetched
            // results would fulfil the need of 150.
            searchHits({
              perPage: hitsToFetch + resultsLength,
              query,
              filters,
              aroundLatLng,
              aroundRadius: radius,
            })
              .then(res => {
                const { hits } = res;

                const uniqueHits = removeDuplicates([
                  ...items,
                  ...hits.map(h => ({ ...h, additional: true })),
                ]);

                return uniqueHits;
              })
              .then(uniqueHits => {
                const toFetch = MAX_HITS - uniqueHits.length;
                if (toFetch > 0) {
                  searchHits({
                    perPage: toFetch + uniqueHits.length,
                    query: '',
                    filters: '',
                    aroundLatLng,
                    aroundRadius: radius,
                  }).then(({ hits }) => {
                    const completeUniqueHits = removeDuplicates([
                      ...uniqueHits,
                      ...hits.map(h => ({ ...h, additional: true })),
                    ]);
                    setHitsToRender(completeUniqueHits.slice(0, 150));
                    setCurrentPage(1);
                  });
                } else {
                  setHitsToRender(uniqueHits.slice(0, 150));
                  setCurrentPage(1);
                }
              });
          } else if (readyToFetch) {
            setHitsToRender(items);
          }
        });
      }, 250);

      setHitsTimer(timer);
    }
    return () => clearTimeout(timer);
  }, [hitsToFetch, readyToFetch]);

  useEffect(() => {
    // Need to update the view count for each hit.
    const recordIds =
      hitsToRender && hitsToRender.length
        ? hitsToRender.map(h => h.objectID)
        : null;
    if (recordIds) {
      updateAlgoliaViews({
        indexName: ALGOLIA_LISTINGS_INDEX,
        recordIds,
      }).catch(err => console.log('Error:failed to update views'));
    }
  }, [hitsToRender]);

  if (error) {
    return <H4 className={css.error}>{error.message}</H4>;
  }

  // if (!hitsToRender.length && status === 'idle') {
  //   return (
  //     <div className={css.emptyStateContainer}>
  //       <p className={css.emptyStateMessage}>
  //         No results found for your query.
  //       </p>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className={css.rentals} id="hits">
        {renderableHits.map(i => (
          <ListCard
            rental={i}
            key={i.objectID}
            currentUserCurrency={currentUserCurrency}
            exchangeRates={exchangeRates}
            onClick={() =>
              sendEvent('click', i, PRODUCT_CLICKED_EVENT, {
                objectIds: [i.objectID],
                ...userTokenMaybe,
              })
            }
            history={props.history}
            routes={props.routesConfiguration}
            queryID={queryID}
            additional={i.additional}
            onActivateListing={props.onActivateListing}
          />
        ))}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default memo(CustomHits);
