import axios from 'axios';

const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET;
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD;
const uploadEndpoint = process.env.REACT_APP_CLOUDINARY_UPLOAD_ENDPOINT.replace(
  '<cloudName>',
  cloudName
);

export const UploadImage = async ({ file, id }) => {
  try {
    // FormData object to hold the file to upload
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('public_id', id);

    // Make POST request to Cloudinary upload API
    const response = await axios.post(uploadEndpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle the response from Cloudinary
    // console.log('Upload response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};


/**
 *A function that takes a secure url and return its different variants.
 * @param {*} secureUrl 
 * @returns object containing different variants
 */
 export function getUrlVariants(secureUrl) {
  
  const urlParts = secureUrl.split('/');
  const versionIndex = urlParts.findIndex(part => part.startsWith('v'));
  const publicId = urlParts.slice(versionIndex + 1).join('/').replace('.jpg', '');
  const baseUrl = urlParts.slice(0, versionIndex).join('/');

  const version = urlParts[versionIndex];
 
  // Define the variants
  const variants = {
      'listing-card-6x': { transformation: 'w_2400,h_2400', height: 2400, width: 2400, name: 'listing-card-6x' },
      'listing-card-4x': { transformation: 'w_1600,h_1600', height: 1600, width: 1600, name: 'listing-card-4x' },
      'listing-card-2x': { transformation: 'w_800,h_800', height: 800, width: 800, name: 'listing-card-2x' },
      'listing-card': { transformation: 'w_400,h_400', height: 400, width: 400, name: 'listing-card' },
      'landscape-crop': { transformation: 'c_crop,w_400,h_267', height: 267, width: 400, name: 'landscape-crop' },
      'landscape-crop2x': { transformation: 'c_crop,w_800,h_533', height: 533, width: 800, name: 'landscape-crop2x' },
      'landscape-crop4x': { transformation: 'c_crop,w_1600,h_1066', height: 1066, width: 1600, name: 'landscape-crop4x' },
      'landscape-crop6x': { transformation: 'c_crop,w_2400,h_1602', height: 1602, width: 2400, name: 'landscape-crop6x' },
      'scaled-small': { transformation: 'w_320,h_320', height: 320, width: 320, name: 'scaled-small' },
      'scaled-medium': { transformation: 'w_750,h_750', height: 750, width: 750, name: 'scaled-medium' },
      'scaled-large': { transformation: 'w_1024,h_1024', height: 1024, width: 1024, name: "scaled-large" },
      'scaled-xlarge': { transformation: 'w_2400,h_2400', height: 2400, width: 2400, name: 'scaled-xlarge' },
      'square-small': { transformation: 'c_crop,w_240,h_240', height: 240, width: 240, name: 'square-small' },
      'square-small2x': { transformation: 'c_crop,w_480,h_480', height: 480, width: 480, name: 'square-small2x' },
      facebook: { transformation: 'c_crop,w_1200,h_630', height: 630, width: 1200, name: "facebook" },
      twitter: { transformation: 'c_crop,w_600,h_314', height: 314, width: 600, name: 'twitter' }
  };

  // Construct the URLs for each variant
  const variantUrls = {};
  for (const [variantName, { transformation, height, width, name }] of Object.entries(variants)) {
      variantUrls[variantName] = {
          publicId: publicId,
          url: `${baseUrl}/${transformation}/${version}/${publicId}.jpg`,
          height,
          width,
          name
      };
  }

  return variantUrls;
}
